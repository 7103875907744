import * as $ from 'jquery';
//import 'owl.carousel';


// cookies
jQuery.fn.extend({
    toggleText: function(stateOne, stateTwo) {
        return this.each(function() {
            stateTwo = stateTwo || '';
            $(this).text() !== stateTwo && stateOne ? $(this).text(stateTwo)
                : $(this).text(stateOne);
        });
    }
});
function setCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    }
    else var expires = '';
    document.cookie = name+"="+value+expires+"; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function deleteCookie(name) {
    setCookie(name,"",-1);
}

function hasVoted(dataId){
    var userVoted = readCookie('user_voted_'+ dataId);
    if( userVoted ){
        return true;
    }
    return false;
}

function hasClicked(data){
    var userClicked = readCookie('user_clicked_'+ data);
    if( userClicked ){
        return true;
    }
    return false;
}



var userip = readCookie("userip");

if (!userip){
    $('#warning_popup').fadeIn(200).fadeTo(400, 1);
}
else{
    $('#warning_popup').hide();
}

$('#warning_popup_close').on('click', function(){
    var now = new Date();
    now.setTime(now.getTime() + 24 * 3600 * 1000);
    var ip = $("input[name='ip']").val();
    console.log(ip);
    document.cookie = "userip=" + ip +";path=/;expires="+ now.toUTCString();
    $('#warning_popup').fadeOut(200).fadeTo(400, 1);
});

/*Scroll top*/
$(window).scroll(function(){
    if ($(this).scrollTop() > 100) {
        $(".to-top").addClass('active');
    }
    else {
        $(".to-top").removeClass('active');
    }
});

/*Scroll top Click*/
$(".to-top").on("click", function () {
    $('html, body').animate({scrollTop: 0}, 1000);
});


function isScrolledIntoView(elem){
    if(elem.length != 0){
        var $elem = $(elem);
        var $window = $(window);
        var docViewTop = $window.scrollTop();
        var docViewBottom = docViewTop + $window.height();
        var elemTop = $elem.offset().top;
        var elemBottom = elemTop + $elem.height();
        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }
    else{
        return false;
    }
}

$(document).ready(function(){
    "use strict";

    /*  function isElementScrolledIntoView(elem) {
         var docViewTop = $(window).scrollTop();
         var docViewBottom = docViewTop + $(window).height();

         var elemTop = $(elem).offset().top;
         var elemBottom = elemTop + $(elem).height();

         return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
     }

     // ----------------------------------------- fixed-contact-form --------------------------------------------------
    /* if($(".category-subscribe-form-js1q").offset()){
         $(window).scroll(function(){
             if($(window).width() > 1199){
                 var formOffset = $(".category-subscribe-form-js").offset().top;
                 var footerOffset = $('.footer').offset().top;
                 var windowHeight =  $(window).height();
                 var windowScrollTop =  $(window).scrollTop();

                 console.log('Window height: ' + windowHeight);
                 console.log('Form offset top: ' + formOffset);
                 console.log('Window scroll top:' + windowScrollTop );
                 console.log('Footer offset top: ' + footerOffset);
                 if( $(window).scrollTop() >= 580 ){

                     $('.category-subscribe-form-js').addClass('fixed');
                 }
                 if( $(window).scrollTop() < 580 ){
                     $('.category-subscribe-form-js').removeClass('fixed');
                 }
                 if( $(window).scrollTop() >= footerOffset - windowHeight + 80 ) {
                     $('.category-subscribe-form-js').css({'position':'absolute','top': footerOffset - windowHeight - formOffset +'px'});
                 }
                 if( $(window).scrollTop() < footerOffset - windowHeight + 80 && $(window).scrollTop() >= 580 ){
                     $('.category-subscribe-form-js').css({'position':'fixed','top': '-40px'});
                 }
                 if( $(window).scrollTop() < footerOffset - windowHeight + 80 && $(window).scrollTop() < 580 ){
                     $('.category-subscribe-form-js').removeClass('fixed');
                 }
             }

         });
     }*/







    var $jsRating = $(".js-rating");
    // Rating
    $jsRating.hover(
        function(e) {
        },function(e){
            var dataId = $(this).data('id');
            if( !hasVoted(dataId) ) {
                var innerDefaulDatatWidth = $(this).data('default-value');
                var defaultWidth = parseInt(innerDefaulDatatWidth) * 20;
                $(this).find('.js-rating_inner').css("width", defaultWidth + "%");
            }
        }
    );
    $jsRating.mousemove(function(e){
        var dataId = $(this).data('id');
        if( !hasVoted(dataId) ){
            var actionWidth = e.offsetX;
            if(actionWidth > 100){
                actionWidth = 100;
            }
            $(this).find('.js-rating_inner').css("width", actionWidth + "%");
        }
    });
    $jsRating.on('click',function(e) {
        var dataId = $(this).data('id');
        if( !hasVoted(dataId) ){
            var $that = $(this);
            var dataVotes = $that.data('votes');
            var updatedVotes = dataVotes+1;
            var innerWidth = $that.find('.js-rating_inner').width();
            var perc = e.offsetX;
            $that.find('.js-rating_inner').css("width", perc + "%");
            $.ajax({
                type: 'POST',
                url: votesScript.url,
                data:{
                    action: votesScript.action,
                    nonce: votesScript._wp_nonce,
                    data_id: dataId,
                    rating: innerWidth,
                    votes: updatedVotes
                },
                success: function (result) {
                    var res = JSON.parse(result);
                    if( res.status === 'success' ){
                        $('.js-votes').text(updatedVotes);
                        $jsRating.data('votes',updatedVotes);
                        $jsRating.data('default-value',innerWidth);
                        setCookie('user_voted_'+ dataId,'voted',5);
                    }else{
                        console.log(res.status);
                    }
                }
            });
        }
    });

    // Likes clicks
    $('.js-like').on('click',function(){
        var currentItem = $('.gallery-item-js.active');
        var currentPostId = parseInt(currentItem.data('post-id'));
        var activeSlide = parseInt(currentItem.data('order'));
        var srcUrl = currentItem.data('src-url');
        var currentLikes = parseInt(currentItem.data('likes'));
        if( !hasClicked(srcUrl) ){
            $.ajax({
                type: 'POST',
                url: likesScript.url,
                data:{
                    action: likesScript.action,
                    nonce: likesScript._wp_nonce,
                    post_id: currentPostId,
                    active_slide: activeSlide,
                    current_likes: currentLikes
                },
                success: function (result) {
                    var res = JSON.parse(result);
                    if( res.status === 'success' ){
                        $('.total-virtual-likes').text(currentLikes+1);
                        currentItem.find('.likes-total').text(currentLikes+1);
                        setCookie('user_clicked_'+ srcUrl,'clicked',5);
                    }else{
                        console.log(res.status);
                    }
                }
            });
        }else{
            console.log('You have already clicked this photo');
        }

    });







    var siteBody = $("body");
    var siteHtml = $("html");
    /**
     * ********************************************************
     * ---------------------Scroll Effects---------------------
     * ========================================================
     * */


    var wwaItem = document.getElementsByClassName('wwa-item');
    function wwaEffect(k){
        var z = wwaItem[k];
        setTimeout(function() {$(z)}, 1 + k * 500);
    }
    var wwdItem = document.getElementsByClassName('wwd-item');
    function wwdEffect(l){
        var w = wwdItem[l];
        setTimeout(function() {$(w).css({'opacity': 1})}, 1 + l * 500);
    }
    var gallItem = document.getElementsByClassName('gallery-item');
    function gallEffect(m){
        var g = gallItem[m];
        setTimeout(function() {$(g).css({'opacity': 1})}, 1 + m * 500);
    }
    var ratingItem = document.getElementsByClassName('rating-item');
    function ratingEffect(m){
        var r = ratingItem[m];
        setTimeout(function() {$(r).css({'opacity': 1})}, 1 + m * 500);
    }
    var prlItem = document.getElementsByClassName('price-list-item');
    function prlEffect(m){
        var p = prlItem[m];
        setTimeout(function() {$(p).css({'opacity': 1})}, 1 + m * 500);
    }
    //c-team-item
    var ctItem = document.getElementsByClassName('c-team-item');
    function ctEffect(m){
        var g = ctItem[m];
        setTimeout(function() {$(g).css({'opacity': 1})}, 1 + m * 500);
    }


    (function($) {
        var clientHeight = $(window).height();
        var items = [];
        $(document).scroll(function() {
            if(isScrolledIntoView($('.wwa-item'))){
                for(var k = 0; k < wwaItem.length; k++){
                    wwaEffect(k);
                }
            }
            if(isScrolledIntoView($('.wwd-item'))){
                for(var l = 0; l < wwdItem.length; l++){
                    wwdEffect(l);
                }
            }
            if(isScrolledIntoView($('.gallery-item'))){
                for(var m = 0; m < gallItem.length; m++){
                    gallEffect(m);
                }
            }
            if(isScrolledIntoView($('.rating-item'))){
                for(var m = 0; m < ratingItem.length; m++){
                    ratingEffect(m);
                }
            }
            if(isScrolledIntoView($('.price-list-item'))){
                for(var n = 0; n < prlItem.length; n++){
                    prlEffect(n);
                }
            }
            if(isScrolledIntoView($('.c-team-item'))){
                for(var o = 0; o < ctItem.length; o++){
                    ctEffect(o);
                }
            }


            for(var i = 0; i < items.length; i++) {
                if(items[i].ele.offset().top - $(document).scrollTop() - clientHeight < 350) {
                    if(typeof items[i].arg === 'string') {
                        items[i].ele.addClass(items[i].arg);
                    } else if(items[i].arg instanceof Function) {
                        items[i].arg.call();
                    }
                }
            }
        });
        jQuery.fn.extend({
            listenedByScroll: function(arg) {
                jQuery(document).scroll();
                if(!arg) return this;
                items.push({ele: $(this), arg: arg});
                return this;
            }
        });
    })(jQuery);

    /*Menu*/

    $(".trigger").on('click',function () {
        $('.header').slideToggle();
        $(this).toggleClass("opened");
        $('body').toggleClass("shadow");
    });

    /*$('.main-menu li.menu-item-has-children').append('<span class="subMenuButton fa fa-angle-down"></span>');
    $('.subMenuButton').on('click',function () {
        var t = $(this);
        if(t.hasClass('open')) {
            t.removeClass('open').prev().slideUp(300);
        } else {
            t.removeClass('open').prev().slideDown(300);
            t.addClass('open');
        }
    });*/
    //Menu Close
    $(document).mouseup(function (e){
        var div = $(".main-menu,#menuOpen");
        if (!div.is(e.target)
            && div.has(e.target).length === 0) {
            div.removeClass('opened');
            $('.hamburger').removeClass('is-active');
            //siteBody.removeClass('shadow');
            //siteHtml.removeClass('shadow');
        }
    });

    /*Scroll top*/
    $(window).scroll(function(){
        if ($(this).scrollTop() > 100) {
            $(".to-top-btn").addClass('active');
        }
        else {
            $(".to-top-btn").removeClass('active');
        }
    });

    /*Scroll top Click*/
    $(".to-top-btn").on("click", function () {
        $('html, body').animate({scrollTop: 0}, 1000);
    });

    $('.js-faq-block').on('click',function(){
        const $faqItem = $('.js-faq-block');
        const $answer = $('.js-faq-answer');
        if( $(this).hasClass('active') ){
            $(this).removeClass('active');
            $(this).find('.js-faq-answer').slideUp(300);
        }else{
            //$faqItem.removeClass('active');
            $(this).addClass('active');
            // $answer.slideUp(300);
            $(this).find('.js-faq-answer').slideDown(300);
        }


        //$(this).hasClass("opened") ? $(this).next().slideToggle(300);
        //$(this).removeClass("opened");
        //$(this).next().slideToggle(300);
        //$(this).addClass("opened");
        //$(this).parent().addClass('active');
        //$(this).closest('js-faq-answer').addClass('active');
    });



    // 404 go back
    $('.back').on('click', function(){
        setTimeout('history.back()', 1000);
    });

    /**
     * Displaying comment by click
     */
    var $body = $('body');

    $body.on('click','.show-comments-js',function(){
        $('#comments').slideToggle();
        $(this).find('i').toggleClass("fa-caret-up fa-caret-down");
        $(this).find('.show-comments-text-js').toggleText('Hide Comments','Show Comments');
    });

    /**
     * Comments rating
     */
    $body.on('change',"input[name='rating']",function(){
        var rating = parseInt($(this).val());
        $('.heart-keeper').each(function(i){
            if( i+1 <= rating ){
                $(this).find('i').removeClass('fa-heart-o').addClass('fa-heart');
            }else{
                $(this).find('i').removeClass('fa-heart').addClass('fa-heart-o');
            }
        })
    });






    /**
     * +++++++++++++++++++++++++++++++++++++++++++++++++
     * ===================== Ajax solutions ============
     * +++++++++++++++++++++++++++++++++++++++++++++++++
     */

    /**
     * *************************************************
     * ================ FORMS ==========================
     * *************************************************
     */

    $('#mobile_subscribe_icon_btn').on('click',function(){
        $('#category_subscribe_form').toggle();
        $('body').addClass("shadow");
    });
    $('.mobile-close-js').on('click',function(){
        $('#category_subscribe_form').hide();
        $('body').removeClass("shadow");
    });
    // Home subscribe form, category subscribe form
    $('#sf_form').on('submit',function(e){
        e.preventDefault();
        var $that = $(this);
        var nonce = $that.find("input[name='sf_nonce']").val();
        var customerEmail = $that.find("input[name='sf_email']").val();
        var mail_pattern=/[0-9a-z_]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
        var check_mail = mail_pattern.test(customerEmail);
        var userName = $that.find("input[name='sf_name']").val();
        var userLastName = $that.find("input[name='sf_last_name']").val();
        var userCompany = $that.find("input[name='sf_company']").val();
        var agreeReceiveEmails = $that.find("input[name='agree_receive_emails']");
        var recaptchaErrorMsg = $('.recaptcha-error-msg').data('error-text');
        var emailErrorMsg = $('#sf_email_error').data('email-error-msg');

        if( !agreeReceiveEmails.is(':checked') ){
            $("#sf_agree_receive_emails_error").text('Please confirm agreement');
        }else{
            $("#sf_agree_receive_emails_error").text('');
        }
        if( customerEmail === '' ){
            $that.find("input[name='sf_email']").css({'background':'#E8C4C4'});
            $("#sf_email_error").text('Email is required');
        }
        else if( check_mail !== true ){
            $that.find("input[name='sf_email']").css({'background':'#E8C4C4'});
            $("#sf_email_error").text('Not valid email');

        }else{
            $that.find("input[name='sf_email']").css({'background':'#F2F5FA'});
            $("#sf_email_error").text('');
        }
        if( userName === '' ){
            $that.find("input[name='sf_name']").css({'background':'#E8C4C4'});
            $("#sf_name_error").text('Name is required');
        }else{
            $that.find("input[name='sf_name']").css({'background':'#F2F5FA'});
            $("#sf_name_error").text('');
        }
        if( userLastName === '' ){
            $that.find("input[name='sf_last_name']").css({'background':'#E8C4C4'});
            $("#sf_last_name_error").text('Last name is required');
        }else{
            $that.find("input[name='sf_last_name']").css({'background':'#F2F5FA'});
            $("#sf_last_name_error").text('');
        }
        if( userCompany === '' ){
            $that.find("input[name='sf_company']").css({'background':'#E8C4C4'});
            $("#sf_company_error").text('Company is required');
        }else{
            $that.find("input[name='sf_company']").css({'background':'#F2F5FA'});
            $("#sf_company_error").text('');
        }

        if( check_mail === true && userName !== '' && agreeReceiveEmails.is(':checked') && userLastName !== '' && userCompany !== '' ){

            $.ajax({
                type: 'POST',
                url: localizedScript.url,
                data:{
                    action: 'subscribe_form',
                    nonce: nonce,
                    user_name: userName,
                    user_last_name: userLastName,
                    user_company: userCompany,
                    user_email: customerEmail,
                    captcha: grecaptcha.getResponse()
                },
                success: function (result) {
                    var res = JSON.parse(result);
                    console.log(result);
                    if(res.status === 'success'){
                        $that[0].reset();
                        grecaptcha.reset();
                        $('.thank-popup-wrapper').fadeIn();
                        setTimeout(function(){$('.thank-popup-wrapper').fadeOut()}, 5000);
                    }
                    else if(res.status === 'user_already_exists'){
                        $("input[name='sf_email']").css({'background':'#E8C4C4'});
                        $("#sf_email_error").text('Email: '+customerEmail+' '+emailErrorMsg);
                        grecaptcha.reset();
                    }
                    else if(res.status === 'post_not_set'){
                        $('.recaptcha-error-msg').html('<p>'+recaptchaErrorMsg+'</p>');
                        setTimeout(function(){
                            $('.recaptcha-error-msg').html('');
                        }, 3000);
                    }
                    else if(res.status === 'failed'){
                        $('.recaptcha-error-msg').html('<p>'+recaptchaErrorMsg+'</p>');
                        setTimeout(function(){
                            $('.recaptcha-error-msg').html('');
                        }, 3000);
                    }
                },
                error: function(jqxhr, status, exception) {
                    console.log('Exception:', exception);
                }

            });
        }
    });

    // Contact us contact form + mask
    if( document.getElementById('cu_phone') ){
        document.getElementById('cu_phone').addEventListener('input', function (e) {
            // var x = e.target.value.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/);
            var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            // e.target.value = !x[2] ? x[1] : '+' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '') + (x[4] ? '-' + x[4] : '');
            e.target.value = !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
        });
    }

    $('#cu_form').on('submit',function(e){
        e.preventDefault();
        var $that = $(this);
        var nonce = $that.find("input[name='cu_nonce']").val();
        var customerEmail = $that.find("input[name='cu_email']").val();
        var mail_pattern=/[0-9a-z_]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
        var check_mail = mail_pattern.test(customerEmail);
        var userName = $that.find("input[name='cu_name']").val();
        var userCompany = $that.find("input[name='cu_company']").val();
        var userSubject = $that.find("input[name='cu_subject']").val();
        var userMessage = $that.find("textarea[name='cu_message']").val();
        var userPhone = $that.find("input[name='cu_phone']").val();
        var phone_pattern = /^\+?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})[-. ]?$/;
        var check_phone = phone_pattern.test(userPhone);
        if( userPhone !== '' && check_phone !== true ){
            $that.find("input[name='cu_phone']").css({'background':'#E8C4C4'});
            $("#cu_phone_error").text('Not valid phone');
            setTimeout(function(){$that.find("input[name='cu_phone']")}, 3000);
        }
        else if (userPhone === ''){
            $that.find("input[name='cu_phone']").css({'background':'#E8C4C4'});
            $("#cu_phone_error").text('Phone number is required');
        }
        else{
            $that.find("input[name='cu_phone']").css({'background':'#F2F5FA'});
            $("#cu_phone_error").text('');
        }
        if( customerEmail === '' ){
            $that.find("input[name='cu_email']").css({'background':'#E8C4C4'});
            setTimeout(function(){$that.find("input[name='cu_email']")}, 3000);
            $('#cu_email_error').text('Email is required');
        }
        else if( check_mail !== true ){
            $that.find("input[name='cu_email']").css({'background':'#E8C4C4'});
            setTimeout(function(){$that.find("input[name='cu_email']")}, 3000);
            $('#cu_email_error').text('Not valid email');
        }else{
            $that.find("input[name='cu_email']").css({'background':'#F2F5FA'});
            $('#cu_email_error').text('');
        }
        if( userName === '' ){
            $that.find("input[name='cu_name']").css({'background':'#E8C4C4'});
            setTimeout(function(){$that.find("input[name='cu_name']")}, 3000);
            $('#cu_name_error').text('Name is required');
        }else{
            $that.find("input[name='cu_name']").css({'background':'#F2F5FA'});
            $('#cu_name_error').text('');
        }
        if( check_phone === true && check_mail === true && userName !== '' ){
            $.ajax({
                type: 'POST',
                url: localizedScript.url,
                data:{
                    action: 'contact_us_form',
                    nonce: nonce,
                    user_name: userName,
                    user_email: customerEmail,
                    user_phone: userPhone,
                    user_company: userCompany,
                    user_subject: userSubject,
                    user_message: userMessage,
                    captcha: grecaptcha.getResponse()
                },
                success: function (result) {
                    console.log(result);
                    var res = JSON.parse(result);
                    if(res.status === 'success'){
                        $that[0].reset();
                        grecaptcha.reset();
                        $('.thank-popup-wrapper').fadeIn();
                        setTimeout(function(){$('.thank-popup-wrapper').fadeOut()}, 5000);
                    }
                    else if(res.status === 'failed'){
                        grecaptcha.reset();
                        $('.recaptcha-error-msg').html('<p style="color:red;">Oops... Recaptcha is not passed!</p>');
                        setTimeout(function(){
                            $('.recaptcha-error-msg').html('');
                        }, 3000);
                    }
                    else if(res.status === 'post_or_nonce_not_set'){
                        grecaptcha.reset();
                        $('.recaptcha-error-msg').html('<p style="color:red;">Oops... Validation is not passed!</p>');
                        setTimeout(function(){
                            $('.recaptcha-error-msg').html('');
                        }, 3000);
                    }
                },
                error: function(jqxhr, status, exception) {
                    console.log('Exception:', exception);
                }
            });
        }
    });

    $('.order-btn-js').on('click',function(){
        var propositionId = $(this).data('order');
        siteBody.addClass('shadow');
        siteHtml.addClass('shadow');
        $('.footer-order-popup').css({'display':'flex'});
        $('#ff_order').val(propositionId);
    });
    $('.footer-order-form-close-js').on('click',function(){
        $('.footer-order-popup').fadeOut();
        siteBody.removeClass('shadow');
        siteHtml.removeClass('shadow');
    });
    $('.footer-order-popup').on('click',function(event){
        if (!$(event.target).closest("form").length) {
            $(".footer-order-popup").fadeOut();
            siteBody.removeClass('shadow');
            siteHtml.removeClass('shadow');
        }
    });
    $('#order_form').on('submit',function(e){
        e.preventDefault();
        var $that = $(this);
        var nonce = $that.find("input[name='order_nonce']").val();
        var orderId = $that.find("input[name='ff_order']").val();
        var orderClientEmail = $that.find("input[name='ff_email']").val();
        var mail_pattern=/[0-9a-z_]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
        var check_mail = mail_pattern.test(orderClientEmail);
        if( orderClientEmail === '' ){
            $that.find("input[name='ff_email']").css({'border':'1px solid red'});
            $("#ff_email_error").text('Email is required');
        }
        else if( check_mail !== true ){
            $that.find("input[name='ff_email']").css({'border':'1px solid red'});
            $("#ff_email_error").text('Not valid email');
        }else{
            $that.find("input[name='ff_email']").css({'border':'1px solid #ccc'});
            $("#ff_email_error").text('');
        }
        if( check_mail == true && orderId != '' ){
            $that.find("input[name='ff_email']").css({'border':'1px solid #ccc'});
            $.ajax({
                type: 'POST',
                url: localizedScript.url,
                data:{
                    action: 'footer_order_form',
                    nonce: nonce,
                    order_id: orderId,
                    user_email: orderClientEmail
                },
                success: function (result) {
                    var res = JSON.parse(result);
                    console.log(result);
                    if(res.status === 'success'){
                        $that[0].reset();
                        $('.thank-popup-wrapper').fadeIn();
                        setTimeout(function(){
                            $('.footer-order-popup').fadeOut();
                            $('.thank-popup-wrapper').fadeOut();
                            siteBody.removeClass('shadow');
                            siteHtml.removeClass('shadow');
                        }, 3000);
                    }
                    else if(res.status === 'failed'){
                        $('#ff_email_error').text('Oops... Something went wrong!');
                        setTimeout(function(){
                            $('#ff_email_error').text('');
                        }, 3000);
                    }
                },
                error: function(jqxhr, status, exception) {
                    console.log('Exception:', exception);
                }
            });
        }
    });
});


